import { Action, createReducer, on } from '@ngrx/store';
import { TemporaryAdmission } from '../../shared/generated/model/temporaryAdmission';
import { AdminActions } from './admin.actions';

export interface AdminState {
  adminApprovalIds: number[];
  selectedAdminApprovalId: number | null;
  adminApprovals: { [standardId: number]: TemporaryAdmission } | null;
  adminApprovalsCount: number | null;
}

const initialState: AdminState = {
  adminApprovalIds: [],
  selectedAdminApprovalId: null,
  adminApprovals: null,
  adminApprovalsCount: null,
};

export const reducer = createReducer(
  initialState,

  /**
   * inital load
   */
  on(AdminActions.LoadAdminApprovals, (state) => ({ ...state })),

  /**
   * add only new standards to state
   */
  on(AdminActions.LoadAdminApprovalsSuccess, (state, { payload }) => {
    const adminApprovalIds = payload.items.map((standard) => standard.id);
    const newAdminApprovalEntities = payload.items.reduce(
      (
        adminApprovals: { [id: number]: TemporaryAdmission },
        adminApproval: TemporaryAdmission,
      ) => ({ ...adminApprovals, [adminApproval.id]: adminApproval }),
      {},
    );

    return {
      ...state,
      adminApprovalIds: [
        //...state.standardIds,
        ...adminApprovalIds,
      ],
      adminApprovals: {
        //...state.standards,
        ...newAdminApprovalEntities,
      },
      selectedAdminApprovalId: state.selectedAdminApprovalId,
      adminApprovalsCount: payload.count,
    };
  }),

  /**
   * loading error, set standards to null
   */
  on(AdminActions.LoadAdminApprovalsError, (state) => ({
    ...state,
    standards: null,
  })),
);

export function adminsReducer(state: AdminState | undefined, action: Action) {
  return reducer(state, action);
}
