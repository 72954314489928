import { createAction, props } from '@ngrx/store';
import {
  Document,
  DocumentDownloadLog,
  DocumentDownloads,
  Documents,
} from '../../shared/generated';

export namespace StandardsActions {
  /**
   * STANDARDS
   */
  export const LoadStandardsByCreditorId = createAction(
    '[Zndl] LOAD_STANDARDS_BY_CREDITOR_ID',
    props<{ payload: { pageIndex: number; pageSize: number } }>(),
  );

  export const LoadStandardsByCreditorIdSuccess = createAction(
    '[Zndl] LOAD_STANDARDS_BY_CREDITOR_ID_SUCCESS',
    props<{ payload: Documents }>(),
  );

  export const LoadStandardsByCreditorIdError = createAction(
    '[Zndl] LOAD_STANDARDS_BY_CREDITOR_ID_ERROR',
  );

  /**
   * Standard downloads
   */
  export const LoadStandardsDownloads = createAction(
    '[Zndl] LOAD_STANDARDS_DOWNLOADS',
    props<{ payload: { pageIndex: number; pageSize: number } }>(),
  );

  export const LoadStandardsDownloadsSuccess = createAction(
    '[Zndl] LOAD_STANDARDS_DOWNLOADS_SUCCESS',
    props<{ payload: DocumentDownloads }>(),
  );

  export const LoadStandardsDownloadsError = createAction(
    '[Zndl] LOAD_STANDARDS_DOWNLOADS_ERROR',
  );

  /**
   * Downloads
   */
  export const DownloadStandard = createAction(
    '[Zndl] DOWNLOAD_STANDARD',
    props<{ payload: { standard: Document; url: string; lang: string } }>(),
  );

  export const DownloadStandardSuccess = createAction(
    '[Zndl] DOWNLOAD_STANDARD_SUCCESS',
    props<{ payload: DocumentDownloadLog }>(),
  );

  export const DownloadStandardError = createAction(
    '[Zndl] DOWNLOAD_STANDARD_ERROR',
  );

  /**
   * Standards download log
   */
  export const LogStandardsDownload = createAction(
    '[Zndl] LOG_STANDARDS_DOWNLOAD',
    props<{ payload: Document }>(),
  );

  export const LogStandardsDownloadSuccess = createAction(
    '[Zndl] LOG_STANDARDS_DOWNLOAD_SUCCESS',
  );

  export const LogStandardsDownloadError = createAction(
    '[Zndl] LOG_STANDARDS_DOWNLOAD_ERROR',
  );
}
