import { createAction, props } from '@ngrx/store';
import {
  Document,
  DocumentDownloadLog,
  DocumentDownloads,
  Documents,
} from '../../shared/generated';

export namespace FirmwaresActions {
  /**
   * FIRMWARES
   */
  export const LoadFirmwaresByCreditorId = createAction(
    '[Zndl] LOAD_FIRMWARES_BY_CREDITOR_ID',
    props<{ payload: { pageIndex: number; pageSize: number } }>(),
  );

  export const LoadFirmwaresByCreditorIdSuccess = createAction(
    '[Zndl] LOAD_FIRMWARES_BY_CREDITOR_ID_SUCCESS',
    props<{ payload: Documents }>(),
  );

  export const LoadFirmwaresByCreditorIdError = createAction(
    '[Zndl] LOAD_FIRMWARES_BY_CREDITOR_ID_ERROR',
  );

  /**
   * Firmware downloads
   */
  export const LoadFirmwaresDownloads = createAction(
    '[Zndl] LOAD_FIRMWARES_DOWNLOADS',
    props<{ payload: { pageIndex: number; pageSize: number } }>(),
  );

  export const LoadFirmwaresDownloadsSuccess = createAction(
    '[Zndl] LOAD_FIRMWARES_DOWNLOADS_SUCCESS',
    props<{ payload: DocumentDownloads }>(),
  );

  export const LoadFirmwaresDownloadsError = createAction(
    '[Zndl] LOAD_FIRMWARES_DOWNLOADS_ERROR',
  );

  /**
   * Downloads
   */
  export const DownloadFirmware = createAction(
    '[Zndl] DOWNLOAD_FIRMWARE',
    props<{ payload: { firmware: Document; url: string } }>(),
  );

  export const DownloadFirmwareSuccess = createAction(
    '[Zndl] DOWNLOAD_FIRMWARE_SUCCESS',
    props<{ payload: DocumentDownloadLog }>(),
  );

  export const DownloadFirmwareError = createAction(
    '[Zndl] DOWNLOAD_FIRMWARE_ERROR',
  );

  /**
   * Firmwares download log
   */
  export const LogFirmwaresDownload = createAction(
    '[Zndl] LOG_FIRMWARES_DOWNLOAD',
    props<{ payload: Document }>(),
  );

  export const LogFirmwaresDownloadSuccess = createAction(
    '[Zndl] LOG_FIRMWARES_DOWNLOAD_SUCCESS',
  );

  export const LogFirmwaresDownloadError = createAction(
    '[Zndl] LOG_FIRMWARES_DOWNLOAD_ERROR',
  );
}
