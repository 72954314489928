import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DrawingDownloadLog, DrawingsService } from '../../shared/generated';
import { DownloaderService } from '../../shared/services/downloader.service';
import { DrawingsActions } from './drawings.actions';

@Injectable()
export class DrawingsEffects {
  private actions$: Actions = inject(Actions);
  /**
   * Load/update drawings
   */
  loadDrawings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrawingsActions.LoadDrawingsByCreditorId),
      map((action) => action.payload),
      switchMap((payload) =>
        this.drawingsService
          .getAllFilteredDrawings(payload.pageIndex, payload.pageSize)
          .pipe(
            map((drawings) =>
              DrawingsActions.LoadDrawingsByCreditorIdSuccess({
                payload: drawings,
              }),
            ),
            catchError(() =>
              of(DrawingsActions.LoadDrawingsByCreditorIdError()),
            ),
          ),
      ),
    ),
  );

  /**
   * Load drawing downloads
   */
  loadDrawingsDownloads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrawingsActions.LoadDrawingsDownloads),
      map((action) => action.payload),
      switchMap((payload) =>
        this.drawingsService
          .getDrawingDownloadLog(payload.pageIndex, payload.pageSize)
          .pipe(
            map((downloadLog) =>
              DrawingsActions.LoadDrawingsDownloadsSuccess({
                payload: downloadLog,
              }),
            ),
            catchError(() => of(DrawingsActions.LoadDrawingsDownloadsError())),
          ),
      ),
    ),
  );

  /**
   * drawings download
   */
  downloadDrawings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrawingsActions.DownloadDrawing),
      map((action) => action.payload),
      switchMap((payload) =>
        this.downloaderService.getFile(payload.url).pipe(
          map((blob) => {
            saveAs(
              blob,
              payload.fileFormat === 'pdf'
                ? payload.drawing.pdf.file_name
                : payload.drawing.dxf.file_name,
            );
            return DrawingsActions.DownloadDrawingSuccess({
              payload: {
                drawing_number: payload.drawing.drawing_number,

                file_name:
                  // tslint:disable-next-line: no-all-duplicated-branches
                  payload.fileFormat === 'pdf'
                    ? payload.drawing.pdf.file_name
                    : payload.drawing.dxf.file_name,
                id: payload.drawing.id,
                is3d_data: payload.drawing.is3d_data,
                is_bom_explosion: payload.drawing.is_bom_explosion,
                version_index: payload.drawing.version_index,
              } as DrawingDownloadLog,
            });
          }),
          catchError(() => of(DrawingsActions.DownloadDrawingError())),
        ),
      ),
    ),
  );

  /**
   * After successful download
   */
  downloadDrawingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrawingsActions.DownloadDrawingSuccess),
      map((action) => action.payload),
      switchMap((logDto: DrawingDownloadLog) =>
        this.drawingsService.createDrawingDownloadLog(logDto).pipe(
          map(() => DrawingsActions.LogDrawingsDownloadSuccess()),
          catchError(() => of(DrawingsActions.LogDrawingsDownloadError())),
        ),
      ),
    ),
  );

  constructor(
    private drawingsService: DrawingsService,
    private downloaderService: DownloaderService,
  ) {}
}
