import { createSelector } from '@ngrx/store';
import { ZndlState } from '..';
import { FirmwaresState } from './firmwares.reducer';

export namespace FirmwaresSelectors {
  export const firmwaresState = (state: ZndlState) => state.firmwares;

  /**
   * Firmwares
   */

  export const getFirmwares = createSelector(
    firmwaresState,
    (state: FirmwaresState) => state.firmwares,
  );

  export const getFirmwareIds = createSelector(
    firmwaresState,
    (state: FirmwaresState) => state.firmwareIds,
  );

  export const getSelectedFirmwareId = createSelector(
    firmwaresState,
    (state: FirmwaresState) => state.selectedFirmwareId,
  );

  export const getSelectedFirmware = createSelector(
    getFirmwares,
    getSelectedFirmwareId,
    (entities, selectedId) =>
      !!entities && !!selectedId ? entities[selectedId] : undefined,
  );

  export const getAllFirmwares = createSelector(
    getFirmwares,
    getFirmwareIds,
    (drawings, ids) => (ids && drawings ? ids.map((id) => drawings[id]) : []),
  );

  export const getFirmwaresCount = createSelector(
    firmwaresState,
    (state: FirmwaresState) => state.firmwaresCount,
  );

  /**
   * Firmwares downloads
   */

  export const getFirmwaresDownloads = createSelector(
    firmwaresState,
    (state: FirmwaresState) => state.firmwaresDownloads,
  );

  export const getFirmwareDownloadIds = createSelector(
    firmwaresState,
    (state: FirmwaresState) => state.firmwareDownloadIds,
  );

  export const getSelectedFirmwareDownloadId = createSelector(
    firmwaresState,
    (state: FirmwaresState) => state.selectedFirmwareDownloadId,
  );

  export const getSelectedFirmwareDownload = createSelector(
    getFirmwaresDownloads,
    getSelectedFirmwareDownloadId,
    (entities, selectedId) =>
      !!entities && !!selectedId ? entities[selectedId] : undefined,
  );

  export const getAllFirmwaresDownloads = createSelector(
    getFirmwaresDownloads,
    getFirmwareDownloadIds,
    (firmwaresDownloads, ids) =>
      ids && firmwaresDownloads ? ids.map((id) => firmwaresDownloads[id]) : [],
  );

  export const getFirmwaresDownloadCount = createSelector(
    firmwaresState,
    (state: FirmwaresState) => state.firmwaresDownloadCount,
  );
}
