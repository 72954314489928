import { createAction, props } from '@ngrx/store';
import {
  Drawing,
  DrawingDownloadLog,
  DrawingDownloads,
  Drawings,
} from '../../shared/generated';

export namespace DrawingsActions {
  /**
   * DRAWINGS
   */
  export const LoadDrawingsByCreditorId = createAction(
    '[Zndl] LOAD_DRAWINGS_BY_CREDITOR_ID',
    props<{ payload: { pageIndex: number; pageSize: number } }>(),
  );

  export const LoadDrawingsByCreditorIdSuccess = createAction(
    '[Zndl] LOAD_DRAWINGS_BY_CREDITOR_ID_SUCCESS',
    props<{ payload: Drawings }>(),
  );

  export const LoadDrawingsByCreditorIdError = createAction(
    '[Zndl] LOAD_DRAWINGS_BY_CREDITOR_ID_ERROR',
  );

  /**
   * Drawing downloads
   */
  export const LoadDrawingsDownloads = createAction(
    '[Zndl] LOAD_DRAWINGS_DOWNLOADS',
    props<{ payload: { pageIndex: number; pageSize: number } }>(),
  );

  export const LoadDrawingsDownloadsSuccess = createAction(
    '[Zndl] LOAD_DRAWINGS_DOWNLOADS_SUCCESS',
    props<{ payload: DrawingDownloads }>(),
  );

  export const LoadDrawingsDownloadsError = createAction(
    '[Zndl] LOAD_DRAWINGS_DOWNLOADS_ERROR',
  );

  /**
   * Downloads
   */
  export const DownloadDrawing = createAction(
    '[Zndl] DOWNLOAD_DRAWING',
    props<{
      payload: { drawing: Drawing; url: string; fileFormat: string };
    }>(),
  );

  export const DownloadDrawingSuccess = createAction(
    '[Zndl] DOWNLOAD_DRAWING_SUCCESS',
    props<{ payload: DrawingDownloadLog }>(),
  );

  export const DownloadDrawingError = createAction(
    '[Zndl] DOWNLOAD_DRAWING_ERROR',
  );

  /**
   * Drawings download log
   */
  export const LogDrawingsDownload = createAction(
    '[Zndl] LOG_DRAWINGS_DOWNLOAD',
    props<{ payload: Drawings }>(),
  );

  export const LogDrawingsDownloadSuccess = createAction(
    '[Zndl] LOG_DRAWINGS_DOWNLOAD_SUCCESS',
  );

  export const LogDrawingsDownloadError = createAction(
    '[Zndl] LOG_DRAWINGS_DOWNLOAD_ERROR',
  );
}
