import { createSelector } from '@ngrx/store';
import { ZndlState } from '..';
import { StandardsState } from './standards.reducer';

export namespace StandardsSelectors {
  export const standardsState = (state: ZndlState) => state.standards;

  /**
   * Standards
   */

  export const getStandards = createSelector(
    standardsState,
    (state: StandardsState) => state.standards,
  );

  export const getStandardIds = createSelector(
    standardsState,
    (state: StandardsState) => state.standardIds,
  );

  export const getSelectedStandardId = createSelector(
    standardsState,
    (state: StandardsState) => state.selectedStandardId,
  );

  export const getSelectedStandard = createSelector(
    getStandards,
    getSelectedStandardId,
    (entities, selectedId) =>
      !!entities && !!selectedId ? entities[selectedId] : undefined,
  );

  export const getAllStandards = createSelector(
    getStandards,
    getStandardIds,
    (drawings, ids) => (ids && drawings ? ids.map((id) => drawings[id]) : []),
  );

  export const getStandardsCount = createSelector(
    standardsState,
    (state: StandardsState) => state.standardsCount,
  );

  /**
   * Standards downloads
   */

  export const getStandardsDownloads = createSelector(
    standardsState,
    (state: StandardsState) => state.standardsDownloads,
  );

  export const getStandardDownloadIds = createSelector(
    standardsState,
    (state: StandardsState) => state.standardDownloadIds,
  );

  export const getSelectedStandardDownloadId = createSelector(
    standardsState,
    (state: StandardsState) => state.selectedStandardDownloadId,
  );

  export const getSelectedStandardDownload = createSelector(
    getStandardsDownloads,
    getSelectedStandardDownloadId,
    (entities, selectedId) =>
      !!entities && !!selectedId ? entities[selectedId] : undefined,
  );

  export const getAllStandardsDownloads = createSelector(
    getStandardsDownloads,
    getStandardDownloadIds,
    (standardsDownloads, ids) =>
      ids && standardsDownloads ? ids.map((id) => standardsDownloads[id]) : [],
  );

  export const getStandardsDownloadCount = createSelector(
    standardsState,
    (state: StandardsState) => state.standardsDownloadCount,
  );
}
