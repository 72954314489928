import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap } from '@ngrx/store';
import { AdminEffects, adminsReducer, AdminState } from './admin';
import { DrawingsEffects } from './drawings/drawings.effects';
import { drawingsReducer, DrawingsState } from './drawings/drawings.reducer';
import {
  FirmwaresEffects,
  firmwaresReducer,
  FirmwaresState,
} from './firmwares';
import {
  StandardsEffects,
  standardsReducer,
  StandardsState,
} from './standards';

export interface ZndlState {
  drawings: DrawingsState;
  standards: StandardsState;
  admin: AdminState;
  firmwares: FirmwaresState;
}

export const zndlReducers: ActionReducerMap<ZndlState, any> = {
  drawings: drawingsReducer,
  standards: standardsReducer,
  admin: adminsReducer,
  firmwares: firmwaresReducer,
};

export const zndlEffects = [
  EffectsModule.forFeature([
    DrawingsEffects,
    StandardsEffects,
    AdminEffects,
    FirmwaresEffects,
  ]),
];
