import { Action, createReducer, on } from '@ngrx/store';
import {
  Document,
  DocumentDownload,
  DocumentDownloadLog,
} from '../../shared/generated';
import { StandardsActions } from './standards.actions';

export interface StandardsState {
  standardIds: number[];
  selectedStandardId: number | null;
  standards: { [standardId: number]: Document } | null;
  standardsCount: number | null;
  standardDownloadIds: number[];
  selectedStandardDownloadId: number | null;
  standardsDownloads: {
    [standardDownloadId: number]: DocumentDownloadLog;
  } | null;
  standardsDownloadCount: number | null;
}

const initialState: StandardsState = {
  standardIds: [],
  selectedStandardId: null,
  standards: null,
  standardsCount: null,
  standardDownloadIds: [],
  selectedStandardDownloadId: null,
  standardsDownloads: null,
  standardsDownloadCount: null,
};

export const reducer = createReducer(
  initialState,

  /**
   * inital load
   */
  on(StandardsActions.LoadStandardsByCreditorId, (state) => ({ ...state })),

  /**
   * add only new standards to state
   */
  on(
    StandardsActions.LoadStandardsByCreditorIdSuccess,
    (state, { payload }) => {
      const standardIds = payload.items.map((standard) => standard.id);
      const newStandardEntities = payload.items.reduce(
        (standards: { [id: number]: Document }, standard: Document) => ({
          ...standards,
          [standard.id]: standard,
        }),
        {},
      );
      // if filtering and pagination is done in backend we can´t do this logic in frontend
      // const standardsList = payload.items;
      // const newStandards = standardsList.filter((standard) =>
      //   state.standards ? !state.standards[standard.number] : true,
      // );
      // const newStandardIds = newStandards.map((standard) => standard.number);
      // const newStandardEntities = newStandards.reduce(
      //   (standards: { [id: string]: StandardDto }, standard: StandardDto) => {
      //     return { ...standards, [standard.number]: standard };
      //   },
      //   {},
      // );

      return {
        ...state,
        standardIds: [
          //...state.standardIds,
          ...standardIds,
        ],
        standards: {
          //...state.standards,
          ...newStandardEntities,
        },
        selectedStandardsId: state.selectedStandardId,
        standardsCount: payload.count,
      };
    },
  ),

  /**
   * loading error, set standards to null
   */
  on(StandardsActions.LoadStandardsByCreditorIdError, (state) => ({
    ...state,
    standards: null,
  })),

  /**
   * inital load of downloads
   */
  on(StandardsActions.LoadStandardsDownloads, (state) => ({ ...state })),

  /**
   * add only new standard downloads to state
   */
  on(StandardsActions.LoadStandardsDownloadsSuccess, (state, { payload }) => {
    const standardDownloadIds = payload.items.map((download) => download.id);
    const standardDownloadsList = payload.items.reduce(
      (
        downloads: { [id: number]: DocumentDownload },
        download: DocumentDownload,
      ) => ({ ...downloads, [download.id]: download }),
      {},
    );

    // if filtering and pagination is done in backend we can´t do this logic in frontend
    // const newDownloads = standardDownloadsList.filter((download) =>
    //   state.standardsDownloads ? !state.standardsDownloads[download.id] : true,
    // );
    // const newStandardDownloadIds = newDownloads.map((download) => download.id);
    // const newStandardDownloadEntities = newDownloads.reduce(
    //   (downloads: { [id: number]: DownloadDto }, download: DownloadDto) => {
    //     return { ...downloads, [download.id]: download };
    //   },
    //   {},
    // );
    return {
      ...state,
      standardDownloadIds: [
        //...state.standardDownloadIds,
        ...standardDownloadIds,
      ],
      standardsDownloads: {
        //...state.standardsDownloads,
        ...standardDownloadsList,
      },
      selectedStandardsDownloadId: state.selectedStandardDownloadId,
      standardsDownloadCount: payload.count,
    };
  }),

  /**
   * loading error, set standards to null
   */
  on(StandardsActions.LoadStandardsDownloadsError, (state) => ({
    ...state,
  })),
);

export function standardsReducer(
  state: StandardsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
