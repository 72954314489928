export const APPS = {
  ZNDL: {
    ID: 'APP46',
    ROLES: {
      TRUSTED_USER: 'APP46:zndl_trusted_user',
      ADMIN: 'APP46:zndl_administrator',
      ZNDL_ADMIN: 'APP46:Zeichnungs-und-Normendownload_admin',
      THREE_D_DATA: 'APP46:zndl_3d_data',
      DRAWINGS: 'APP46:zndl_drawings',
      STANDARDS: 'APP46:zndl_standards',
    },
  },
};
