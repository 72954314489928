import { createSelector } from '@ngrx/store';
import { AdminState } from '.';
import { ZndlState } from '..';

export namespace AdminSelectors {
  export const adminState = (state: ZndlState) => state.admin;

  /**
   * Admin
   */

  export const getAdminApprovals = createSelector(
    adminState,
    (state: AdminState) => state.adminApprovals,
  );

  export const getAdminApprovalIds = createSelector(
    adminState,
    (state: AdminState) => state.adminApprovalIds,
  );

  export const getSelectedAdminApprovalId = createSelector(
    adminState,
    (state: AdminState) => state.selectedAdminApprovalId,
  );

  export const getSelectedAdminApproval = createSelector(
    getAdminApprovals,
    getSelectedAdminApprovalId,
    (entities, selectedId) =>
      !!entities && !!selectedId ? entities[selectedId] : undefined,
  );

  export const getAllAdminApprovals = createSelector(
    getAdminApprovals,
    getAdminApprovalIds,
    (drawings, ids) => (ids && drawings ? ids.map((id) => drawings[id]) : []),
  );

  export const getAdminApprovalsCount = createSelector(
    adminState,
    (state: AdminState) => state.adminApprovalsCount,
  );
}
