import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DrawingsService } from '../../shared/generated';
import { AdminActions } from './admin.actions';

@Injectable()
export class AdminEffects {
  private actions$: Actions = inject(Actions);
  /**
   * Load admin approvals
   */
  loadAdminApprovals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.LoadAdminApprovals),
      map((action) => action.payload),
      switchMap((payload) =>
        this.drawingsService
          .getAllTemporaryAdmission(payload.pageIndex, payload.pageSize)
          .pipe(
            map((admissions) =>
              AdminActions.LoadAdminApprovalsSuccess({
                payload: admissions,
              }),
            ),
            catchError(() => of(AdminActions.LoadAdminApprovalsError())),
          ),
      ),
    ),
  );

  addAdminApproval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.AddAdminApproval),
      map((action) => action.payload),
      switchMap((payload) =>
        this.drawingsService
          .createTemporaryAdmission(payload.drawingNr, payload.creditorId)
          .pipe(
            map(() => AdminActions.AddAdminApprovalSuccess()),
            catchError(() => of(AdminActions.LoadAdminApprovalsError())),
          ),
      ),
    ),
  );

  removeAdminApproval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.RemoveAdminApproval),
      map((action) => action.payload),
      switchMap((payload) =>
        this.drawingsService.deleteTemporaryAdmission(payload).pipe(
          map(() => AdminActions.RemoveAdminApprovalSuccess()),
          catchError(() => of(AdminActions.RemoveAdminApprovalError())),
        ),
      ),
    ),
  );

  constructor(private drawingsService: DrawingsService) {}
}
