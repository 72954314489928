import { Routes, ActivatedRouteSnapshot } from '@angular/router';
import { AuthGuard } from '@features/auth';
import { APPS } from './shared/data/app-roles';
import { inject } from '@angular/core';

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview',
      },
      {
        path: 'drawings',
        loadChildren: () =>
          import('./drawings/drawings.module').then((m) => m.DrawingsModule),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          permissions: {
            roles: [
              APPS.ZNDL.ROLES.ADMIN,
              APPS.ZNDL.ROLES.TRUSTED_USER,
              APPS.ZNDL.ROLES.ZNDL_ADMIN,
              APPS.ZNDL.ROLES.DRAWINGS,
            ],
          },
          breadcrumb: 'Drawings',
        },
      },
      {
        path: 'standards',
        loadChildren: () =>
          import('./standards/standards.module').then((m) => m.StandardsModule),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          permissions: {
            roles: [
              APPS.ZNDL.ROLES.ADMIN,
              APPS.ZNDL.ROLES.TRUSTED_USER,
              APPS.ZNDL.ROLES.ZNDL_ADMIN,
              APPS.ZNDL.ROLES.STANDARDS,
            ],
          },
          breadcrumb: 'Standards',
        },
      },
      {
        path: 'firmwares',
        loadChildren: () =>
          import('./firmwares/firmwares.module').then((m) => m.FirmwaresModule),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          permissions: {
            roles: [APPS.ZNDL.ROLES.STANDARDS],
          },
          breadcrumb: 'PCBs & Modules SW/HW',
        },
      },
      {
        path: 'overview',
        loadChildren: () =>
          import('./overview-section/overview-section.module').then(
            (m) => m.OverviewSectionModule,
          ),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          permissions: {
            roles: [
              APPS.ZNDL.ROLES.ADMIN,
              APPS.ZNDL.ROLES.TRUSTED_USER,
              APPS.ZNDL.ROLES.ZNDL_ADMIN,
              APPS.ZNDL.ROLES.STANDARDS,
              APPS.ZNDL.ROLES.DRAWINGS,
            ],
          },
          breadcrumb: 'Overview Section',
        },
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          permissions: {
            roles: [APPS.ZNDL.ROLES.ADMIN, APPS.ZNDL.ROLES.ZNDL_ADMIN],
          },
          breadcrumb: 'Admin section',
        },
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];
