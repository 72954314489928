import { Action, createReducer, on } from '@ngrx/store';
import {
  Document,
  DocumentDownload,
  DocumentDownloadLog,
} from '../../shared/generated';
import { FirmwaresActions } from './firmwares.actions';

export interface FirmwaresState {
  firmwareIds: number[];
  selectedFirmwareId: number | null;
  firmwares: { [firmwareId: number]: Document } | null;
  firmwaresCount: number | null;
  firmwareDownloadIds: number[];
  selectedFirmwareDownloadId: number | null;
  firmwaresDownloads: {
    [firmwareDownloadId: number]: DocumentDownloadLog;
  } | null;
  firmwaresDownloadCount: number | null;
}

const initialState: FirmwaresState = {
  firmwareIds: [],
  selectedFirmwareId: null,
  firmwares: null,
  firmwaresCount: null,
  firmwareDownloadIds: [],
  selectedFirmwareDownloadId: null,
  firmwaresDownloads: null,
  firmwaresDownloadCount: null,
};

export const reducer = createReducer(
  initialState,

  /**
   * inital load
   */
  on(FirmwaresActions.LoadFirmwaresByCreditorId, (state) => ({ ...state })),

  /**
   * add only new firmwares to state
   */
  on(
    FirmwaresActions.LoadFirmwaresByCreditorIdSuccess,
    (state, { payload }) => {
      const firmwareIds = payload.items.map((firmware) => firmware.id);
      const newFirmwareEntities = payload.items.reduce(
        (firmwares: { [id: number]: Document }, firmware: Document) => ({
          ...firmwares,
          [firmware.id]: firmware,
        }),
        {},
      );

      return {
        ...state,
        firmwareIds: [
          //...state.firmwareIds,
          ...firmwareIds,
        ],
        firmwares: {
          //...state.firmwares,
          ...newFirmwareEntities,
        },
        selectedFirmwaresId: state.selectedFirmwareId,
        firmwaresCount: payload.count,
      };
    },
  ),

  /**
   * loading error, set firmwares to null
   */
  on(FirmwaresActions.LoadFirmwaresByCreditorIdError, (state) => ({
    ...state,
    firmwares: null,
  })),

  /**
   * inital load of downloads
   */
  on(FirmwaresActions.LoadFirmwaresDownloads, (state) => ({ ...state })),

  /**
   * add only new firmware downloads to state
   */
  on(FirmwaresActions.LoadFirmwaresDownloadsSuccess, (state, { payload }) => {
    const firmwareDownloadIds = payload.items.map((download) => download.id);
    const firmwareDownloadsList = payload.items.reduce(
      (
        downloads: { [id: number]: DocumentDownload },
        download: DocumentDownload,
      ) => ({ ...downloads, [download.id]: download }),
      {},
    );

    // if filtering and pagination is done in backend we can´t do this logic in frontend
    // const newDownloads = firmwareDownloadsList.filter((download) =>
    //   state.firmwaresDownloads ? !state.firmwaresDownloads[download.id] : true,
    // );
    // const newFirmwareDownloadIds = newDownloads.map((download) => download.id);
    // const newFirmwareDownloadEntities = newDownloads.reduce(
    //   (downloads: { [id: number]: DownloadDto }, download: DownloadDto) => {
    //     return { ...downloads, [download.id]: download };
    //   },
    //   {},
    // );
    return {
      ...state,
      firmwareDownloadIds: [
        //...state.firmwareDownloadIds,
        ...firmwareDownloadIds,
      ],
      firmwaresDownloads: {
        //...state.firmwaresDownloads,
        ...firmwareDownloadsList,
      },
      selectedFirmwaresDownloadId: state.selectedFirmwareDownloadId,
      firmwaresDownloadCount: payload.count,
    };
  }),

  /**
   * loading error, set firmwares to null
   */
  on(FirmwaresActions.LoadFirmwaresDownloadsError, (state) => ({
    ...state,
  })),
);

export function firmwaresReducer(
  state: FirmwaresState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
