/**
 * ZNDL API
 * API to interact with ZNDL
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DrawingDocument } from './drawingDocument';


export interface Drawing { 
    id: number;
    drawing_number: string;
    version_index: string;
    is3d_data: boolean;
    is_bom_explosion: boolean;
    pdf: DrawingDocument;
    dxf: DrawingDocument;
    user: string;
    requested: string;
    finished?: string | null;
    status: DrawingStatus;
}
export enum DrawingStatus {
    Pending = 'pending',
    Finished = 'finished',
    Deleted = 'deleted',
    Error = 'error'
};



