import { Action, createReducer, on } from '@ngrx/store';
import { Drawing } from '../../shared/generated';
import { DrawingDownload } from '../../shared/generated/model/drawingDownload';
import { DrawingsActions } from './drawings.actions';

export interface DrawingsState {
  drawingIds: number[];
  selectedDrawingId: number | null;
  drawings: { [drawingId: number]: Drawing } | null;
  drawingsCount: number | null;
  drawingDownloadIds: number[];
  selectedDrawingDownloadId: number | null;
  drawingsDownloads: {
    [drawingsDownloadId: number]: DrawingDownload;
  } | null;
  drawingsDownloadCount: number | null;
}

const initialState: DrawingsState = {
  drawingIds: [],
  selectedDrawingId: null,
  drawings: null,
  drawingsCount: null,
  drawingDownloadIds: [],
  selectedDrawingDownloadId: null,
  drawingsDownloads: null,
  drawingsDownloadCount: null,
};

export const reducer = createReducer(
  initialState,
  /**
   * add only new drawings to state
   */
  on(DrawingsActions.LoadDrawingsByCreditorIdSuccess, (state, { payload }) => {
    // This check has to be removed, after the backend dto was adapted
    let drawingIds;
    let newDrawingEntities;
    if (payload.items) {
      drawingIds = payload.items.map((drawing) => drawing.id);
      newDrawingEntities = payload.items.reduce(
        (drawings: { [drawingId: number]: Drawing }, drawing: Drawing) => ({
          ...drawings,
          [drawing.id]: drawing,
        }),
        {},
      );
    }
    // if filtering and pagination is done in backend we can´t do this logic in frontend
    // const drawingList = payload;
    // const newDrawings = drawingList.filter((drawing) =>
    //   state.drawings ? !state.drawings[drawing.drawingNumber] : true,
    // );
    // const newDrawingIds = newDrawings.map((drawing) => drawing.drawingNumber);
    // const newDrawingEntities = newDrawings.reduce(
    //   (drawings: { [id: string]: Drawing }, drawing: Drawing) => {
    //     return { ...drawings, [drawing.drawingNumber]: drawing };
    //   },
    //   {},
    // );

    return {
      ...state,
      drawingIds: [...drawingIds],
      drawings: { ...newDrawingEntities },
      selectedDrawingId: state.selectedDrawingId,
      drawingsCount: payload.count,
    };
  }),

  /**
   * loading error, set drawings to null
   */
  on(DrawingsActions.LoadDrawingsByCreditorIdError, (state) => ({
    ...state,
    drawings: null,
  })),

  /**
   * inital load of drawings
   */
  on(DrawingsActions.LoadDrawingsDownloads, (state) => ({ ...state })),

  /**
   * add only new drawing downloads to state
   */
  on(DrawingsActions.LoadDrawingsDownloadsSuccess, (state, { payload }) => {
    const drawingDownloadIds = payload.items.map((download) => download.id);
    const drawingDownloadsList = payload.items.reduce(
      (
        downloads: { [id: number]: DrawingDownload },
        download: DrawingDownload,
      ) => ({ ...downloads, [download.id]: download }),
      {},
    );

    // if filtering and pagination is done in backend we can´t do this logic in frontend
    // const newDownloads = standardDownloadsList.filter((download) =>
    //   state.standardsDownloads ? !state.standardsDownloads[download.id] : true,
    // );
    // const newStandardDownloadIds = newDownloads.map((download) => download.id);
    // const newStandardDownloadEntities = newDownloads.reduce(
    //   (downloads: { [id: number]: DownloadDto }, download: DownloadDto) => {
    //     return { ...downloads, [download.id]: download };
    //   },
    //   {},
    // );
    return {
      ...state,
      drawingDownloadIds: [
        //...state.standardDownloadIds,
        ...drawingDownloadIds,
      ],
      drawingsDownloads: {
        //...state.standardsDownloads,
        ...drawingDownloadsList,
      },
      selectedDrawingDownloadId: state.selectedDrawingDownloadId,
      drawingsDownloadCount: payload.count,
    };
  }),

  /**
   * loading error, set standards to null
   */
  on(DrawingsActions.LoadDrawingsDownloadsError, (state) => ({
    ...state,
  })),
);

export function drawingsReducer(
  state: DrawingsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
