import { createAction, props } from '@ngrx/store';
import { TemporaryAdmissions } from '../../shared/generated/model/temporaryAdmissions';
export namespace AdminActions {
  /**
   * Admin
   */
  export const LoadAdminApprovals = createAction(
    '[Zndl] LOAD_ADMIN_APPROVALS',
    props<{ payload: { pageIndex: number; pageSize: number } }>(),
  );

  export const LoadAdminApprovalsSuccess = createAction(
    '[Zndl] LOAD_ADMIN_APPROVALS_SUCCESS',
    props<{ payload: TemporaryAdmissions }>(),
  );

  export const LoadAdminApprovalsError = createAction(
    '[Zndl] LOAD_ADMIN_APPROVALS_ERROR',
  );

  export const AddAdminApproval = createAction(
    '[Zndl] ADD_ADMIN_APPROVAL',
    props<{ payload: { drawingNr: string; creditorId: string } }>(),
  );

  export const AddAdminApprovalSuccess = createAction(
    '[Zndl] ADD_ADMIN_APPROVAL_SUCCESS',
  );

  export const AddAdminApprovalError = createAction(
    '[Zndl] ADD_ADMIN_APPROVAL_ERROR',
  );

  export const RemoveAdminApproval = createAction(
    '[Zndl] REMOVE_ADMIN_APPROVAL',
    props<{ payload: number }>(),
  );

  export const RemoveAdminApprovalSuccess = createAction(
    '[Zndl] REMOVE_ADMIN_APPROVAL_SUCCESS',
  );

  export const RemoveAdminApprovalError = createAction(
    '[Zndl] REMOVE_ADMIN_APPROVAL_ERROR',
  );
}
