import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DocumentDownloadLog, StandardsService } from '../../shared/generated';
import { DownloaderService } from '../../shared/services/downloader.service';
import { StandardsActions } from './standards.actions';

@Injectable()
export class StandardsEffects {
  private actions$: Actions = inject(Actions);
  /**
   * Load standards
   */
  loadStandards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandardsActions.LoadStandardsByCreditorId),
      map((action) => action.payload),
      switchMap((payload) =>
        this.standardsService
          .getAllFilteredStandards(payload.pageIndex, payload.pageSize)
          .pipe(
            map((standards) =>
              StandardsActions.LoadStandardsByCreditorIdSuccess({
                payload: standards,
              }),
            ),
            catchError(() =>
              of(StandardsActions.LoadStandardsByCreditorIdError()),
            ),
          ),
      ),
    ),
  );

  /**
   * Load standard downloads
   */
  loadStandardsDownloads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandardsActions.LoadStandardsDownloads),
      map((action) => action.payload),
      switchMap((payload) =>
        this.standardsService
          .getStandardDownloadLog(payload.pageIndex, payload.pageSize)
          .pipe(
            map((downloadLog) =>
              StandardsActions.LoadStandardsDownloadsSuccess({
                payload: downloadLog,
              }),
            ),
            catchError(() =>
              of(StandardsActions.LoadStandardsDownloadsError()),
            ),
          ),
      ),
    ),
  );

  /**
   * standards download
   */
  downloadStandard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandardsActions.DownloadStandard),
      map((action) => action.payload),
      switchMap((payload) =>
        this.downloaderService.getFile(payload.url).pipe(
          map((blob) => {
            saveAs(blob, payload.standard.title + '_' + payload.lang + '.pdf');
            return StandardsActions.DownloadStandardSuccess({
              payload: {
                id: payload.standard.id,
                file_name: payload.standard.file_name,
                number: payload.standard.number,
                version: payload.standard.version,
              } as DocumentDownloadLog,
            });
          }),
          catchError(() => of(StandardsActions.DownloadStandardError())),
        ),
      ),
    ),
  );

  /**
   * After successful download
   */
  downloadStandardSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StandardsActions.DownloadStandardSuccess),
      map((action) => action.payload),
      switchMap((logDto: DocumentDownloadLog) =>
        this.standardsService.createStandardDownloadLog(logDto).pipe(
          map(() => StandardsActions.LogStandardsDownloadSuccess()),
          catchError(() => of(StandardsActions.LogStandardsDownloadError())),
        ),
      ),
    ),
  );

  constructor(
    private standardsService: StandardsService,
    private downloaderService: DownloaderService,
  ) {}
}
