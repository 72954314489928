import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DocumentDownloadLog, DocumentsService } from '../../shared/generated';
import { DownloaderService } from '../../shared/services/downloader.service';
import { FirmwaresActions } from './firmwares.actions';

@Injectable()
export class FirmwaresEffects {
  private actions$: Actions = inject(Actions);
  /**
   * Load firmwares
   */
  loadFirmwares$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmwaresActions.LoadFirmwaresByCreditorId),
      map((action) => action.payload),
      switchMap((payload) =>
        this.documentsService
          .getCreditorFirmwares(payload.pageIndex, payload.pageSize)
          .pipe(
            map((firmwares) =>
              FirmwaresActions.LoadFirmwaresByCreditorIdSuccess({
                payload: firmwares,
              }),
            ),
            catchError(() =>
              of(FirmwaresActions.LoadFirmwaresByCreditorIdError()),
            ),
          ),
      ),
    ),
  );

  /**
   * Load firmware downloads
   */
  loadFirmwaresDownloads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmwaresActions.LoadFirmwaresDownloads),
      map((action) => action.payload),
      switchMap((payload) =>
        this.documentsService
          .getFirmwareDownloads(payload.pageIndex, payload.pageSize)
          .pipe(
            map((downloadLog) =>
              FirmwaresActions.LoadFirmwaresDownloadsSuccess({
                payload: downloadLog,
              }),
            ),
            catchError(() =>
              of(FirmwaresActions.LoadFirmwaresDownloadsError()),
            ),
          ),
      ),
    ),
  );

  /**
   * firmwares download
   */
  downloadFirmware$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmwaresActions.DownloadFirmware),
      map((action) => action.payload),
      switchMap((payload) =>
        this.downloaderService.getFile(payload.url).pipe(
          map((blob) => {
            saveAs(blob, payload.firmware.title + '.zip');
            return FirmwaresActions.DownloadFirmwareSuccess({
              payload: {
                id: payload.firmware.id,
                file_name: payload.firmware.file_name,
                number: payload.firmware.number,
                version: payload.firmware.version,
              } as DocumentDownloadLog,
            });
          }),
          catchError(() => of(FirmwaresActions.DownloadFirmwareError())),
        ),
      ),
    ),
  );

  /**
   * After successful download
   */
  downloadFirmwareSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmwaresActions.DownloadFirmwareSuccess),
      map((action) => action.payload),
      switchMap((logDto: DocumentDownloadLog) =>
        this.documentsService.createDocumentDownloadLog(logDto).pipe(
          map(() => FirmwaresActions.LogFirmwaresDownloadSuccess()),
          catchError(() => of(FirmwaresActions.LogFirmwaresDownloadError())),
        ),
      ),
    ),
  );

  constructor(
    private documentsService: DocumentsService,
    private downloaderService: DownloaderService,
  ) {}
}
