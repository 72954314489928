import { Component, OnInit } from '@angular/core';
import { Menu } from '@design-system/feature/app-wrapper-v2';
import { UserService } from '@features/auth';
import { APPS } from './shared/data/app-roles';

@Component({
  selector: 'zndl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  menu: Menu = {
    title: 'ZNDL',
    titleIcon: 'view_list',
    navigation: [],
  };

  constructor(public userService: UserService) {}

  ngOnInit() {
    this.userService.isAuthorized$.subscribe((authorized) => {
      if (authorized) {
        this.assembleSidebar(
          this.userService.hasRole(APPS.ZNDL.ROLES.ADMIN),
          this.userService.hasRole(APPS.ZNDL.ROLES.TRUSTED_USER),
          this.userService.hasRole(APPS.ZNDL.ROLES.ZNDL_ADMIN),
          this.userService.hasRole(APPS.ZNDL.ROLES.DRAWINGS),
          this.userService.hasRole(APPS.ZNDL.ROLES.STANDARDS),
        );
      }
    });
  }

  assembleSidebar(
    admin: boolean,
    trustedUser: boolean,
    zndlAdmin: boolean,
    drawingsUser: boolean,
    standardsUser: boolean,
  ): void {
    this.menu.navigation.push({
      title: 'overview.title',
      icon: 'view_headline',
      routerLink: '/overview',
    });
    if (admin || trustedUser || zndlAdmin || drawingsUser)
      this.menu.navigation.push({
        title: 'drawings.title',
        icon: 'create',
        routerLink: '/drawings',
        children: [
          {
            title: 'drawings.overview_category',
            routerLink: '/drawings/overview',
          },
          {
            title: 'drawings.downloads_category',
            routerLink: '/drawings/downloads',
          },
        ],
      });
    if (admin || trustedUser || zndlAdmin || standardsUser)
      this.menu.navigation.push({
        title: 'standards.title',
        icon: 'note',
        routerLink: '/standards',
        children: [
          {
            title: 'standards.overview_category',
            routerLink: '/standards/overview',
          },
          {
            title: 'standards.downloads_category',
            routerLink: '/standards/downloads',
          },
        ],
      });
    if (standardsUser)
      this.menu.navigation.push({
        title: 'firmwares.title',
        icon: 'settings',
        routerLink: '/firmwares',
        children: [
          {
            title: 'firmwares.overview_category',
            routerLink: '/firmwares/overview',
          },
          {
            title: 'firmwares.downloads_category',
            routerLink: '/firmwares/downloads',
          },
        ],
      });
    if (admin || zndlAdmin)
      this.menu.navigation.push({
        title: 'admin.title',
        icon: 'verified_user',
        routerLink: '/admin',
      });
  }
}
